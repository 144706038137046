<template>
  <div>
    <Card>
      <template v-slot:header>{{ $t("de0359b") }}</template>
      <div class="form-box">
        <Enterprise
          v-if="userInfo.userType === 'ENTERPRISE'"
          ref="form"
          :disabled="!isEdit"
        />
        <Lawyer
          v-else-if="userInfo.userType === 'LAWYER'"
          ref="form"
          :disabled="!isEdit"
        />
        <Personal
          v-else-if="userInfo.userType === 'PERSON'"
          ref="form"
          :disabled="!isEdit"
        />
        <!-- 机构用户 -->
        <Organization
          v-else-if="
            userInfo.userType === 'LAW_OFFICE' ||
            userInfo.userType === 'TRANSLATE'
          "
          ref="form"
          :disabled="!isEdit"
        />
        <div
          :class="`button-container ${
            userInfo.userType == 'PERSON' ? 'align-center' : ''
          }`"
        >
          <div class="buttons">
            <ActionButton
              v-if="!isEdit"
              key="edit"
              class="edit-button"
              @click="isEdit = true"
            >
              {{ $t("7fae2f0") }}
            </ActionButton>
            <ActionButton
              v-if="isEdit"
              key="save"
              style="width: 160px"
              @click="handleClick"
            >
              {{ $t("476103a") }}
            </ActionButton>
            <el-button v-if="isEdit" style="width: 160px" @click="handleCancel">
              {{ $t("6b0feee") }}
            </el-button>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Card from "@/components/Card";
import ActionButton from "@/components/ActionButton";

import Enterprise from "./enterprise";
import Lawyer from "./lawyer";
import Personal from "./personal";
import Organization from "./organization";

export default {
  components: {
    Enterprise,
    Lawyer,
    Personal,
    Organization,
    Card,
    ActionButton,
  },
  data() {
    return {
      avatarUrl: "",
      isEdit: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.avatarUrl = this.userInfo.picture;
  },
  methods: {
    ...mapActions(["updateAccount"]),
    handleAvatarChange(url) {
      this.avatarUrl = url;
    },
    handleClick() {
      this.$refs["form"].$refs["form"].validate((valid) => {
        console.log(this.$refs["form"].values, valid, "11");
        if (valid) {
          // api.update(this.$refs['form'].values, this.userInfo.userType)
          this.updateAccount(
            this.$refs["form"].values,
            this.userInfo.userType
          ).then((res) => {
            this.isEdit = false;
            this.$message.success("修改成功");
          });
        }
      });
    },
    handleCancel() {
      this.isEdit = false;
      this.$refs["form"].reset();
    },
  },
};
</script>

<style scoped lang="less">
.form-box {
  position: relative;
  margin-top: 30px;
  padding: 0 160px;
}
.edit-button {
  width: 360px;
  // margin-right: 48px;
}
.button-container {
  text-align: right;
  margin-right: 48px;
}
.button-container.align-center {
  text-align: center;
  margin-right: 0;
}
.buttons {
  width: 360px;
  display: inline-flex;
  justify-content: space-between;
}

.buttons.align-center {
  margin: 0 auto;
  text-align: center;
}
</style>
