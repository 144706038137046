<template>
  <div
    class="avatar"
    :style="`background: url(${value || headImage}) center center / cover no-repeat`"
  >
    <el-upload
      v-if="!disabled"
      ref="upload"
      class="uploadenclosure"
      :headers="headers"
      :action="actionUrl"
      :multiple="false"
      :limit="1"
      :file-list="fileList"
      :on-success="uploadSuccess"
      :before-upload="beforeUpload"
      :show-file-list="false"
    >
      <div class="edit-mask">
        <Icon :src="editImage" size="10px" no-margin />
        <span style="margin-left: 5px">{{ $t('7fae2f0') }}</span>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Icon from '@/components/Icon'
import editImage from '@/assets/usercenter/edit.png'
import headImage from '@/assets/common/head.png'
import { getToken } from '@/utils/auth'
import { isImage } from '@/utils/validation'

const actionUrl = '/api/frLegalPlatform/common/qiniu/upload'

export default {
  name: 'Avatar',
  components: {
    Icon,
  },
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: headImage
    },
    disabled: Boolean,
  },
  data() {
    return {
      headImage,
      editImage,
      actionUrl,
      fileList: [],
      headers: {
        accessToken: getToken(),
      },
    }
  },
  methods: {
    ...mapActions(['updateAvatar']),
    uploadSuccess(response, file, fileList) {
      // console.log(response, file, fileList);
      // this.values.annex = response.data.url
      // this.updateAvatar({picture: response.data.url})
      this.$emit('change', response.data.url)
      this.$refs['upload'].clearFiles() // 清除文件以允许第二次上传。
    },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt10M = file.size / 1024 / 1024 < 10

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isImage(file.type)) {
        this.$message.error('只能上传jpeg和png格式的图片！')
        return false
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB！')
      }
      return isLt10M
    },
  },
}
</script>

<style scoped lang="less">
.avatar {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #aaaaaa;
  overflow: hidden;
}
.edit-mask {
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  color: white;

  padding: 5px 0;

  font-size: 12px;
  text-align: center;
}
.image-slot {
  img {
    width: 120px;
    height: 120px;
  }
}
</style>
