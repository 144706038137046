<template>
  <div>
    <div class="header">
      <Avatar
        v-model="values.picture"
        :value="values.picture"
        :disabled="disabled"
      />

      <h2>{{ userInfo.username }}</h2>
      <p>{{ ACCOUNT_TYPE[values.organizationType] }}</p>
    </div>
    <el-form
      ref="form"
      label-suffix="："
      label-width="150px"
      class="demo-form-inline"
      :model="values"
      :rules="rules"
      :disabled="disabled"
      :label-position="labelposition"
    >
      <el-form-item required :label="$t('fafa54b')" prop="name">
        <el-input
          v-model="values.name"
          :placeholder="$t('520f308')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('a27362a')" prop="legalPerson">
        <el-input
          v-model="values.legalPerson"
          :placeholder="$t('94d558b')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('af12ab4')" prop="idCard">
        <el-input
          v-model="values.idCard"
          :placeholder="$t('8f5073c')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('af975db')" prop="creditCode">
        <el-input
          v-model="values.creditCode"
          :placeholder="$t('1941316')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item
        v-if="userInfo.country === 'CHINA'"
        required
        :label="$t('da5ea34')"
        prop="province"
      >
        <div style="width: 360px">
          <el-select
            v-model="values.province"
            style="width: 180px"
            @change="handleChange"
          >
            <el-option
              v-for="item in areaTree.list"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
          <el-select v-model="values.city" style="width: 180px">
            <el-option
              v-for="item in cities"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </div>
      </el-form-item>
      <div v-if="isChina" class="provinceContainer">
        <el-form-item required :label="autoPrefix('da5ea34')" prop="province">
          <el-select
            v-model="values.province"
            :placeholder="autoPrefix('8c7aeca')"
            @change="changeProvince"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="city" label-width="0">
          <el-select v-model="values.city" :placeholder="autoPrefix('8c7aeca')">
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item
        required
        :label="$t('da5ea34')"
        prop="countryId"
        v-if="!isChina"
      >
        <el-select
          v-model="values.countryId"
          allow-create
          filterable
          style="width: 360px"
          @change="hadnleCountryChange"
        >
          <el-option
            v-for="item in outCountryList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item required :label="$t('148f2dd')" prop="address">
        <el-input
          v-model="values.address"
          :placeholder="$t('979d2a6')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('1f832ea')" prop="establishedTime">
        <el-date-picker
          v-model="values.establishedTime"
          type="date"
          :placeholder="$t('05141ca')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('f548aaa')" prop="fields">
        <el-select
          v-model="values.fields"
          style="width: 360px"
          multiple
          allow-create
          filterable
          @change="handleFieldChange"
        >
          <el-option
            v-for="item in fieldOptions"
            :key="item.name"
            :value="item.name"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        required
        :label="$t('service.selectionList[0]')"
        prop="serviceAreas"
      >
        <el-select
          v-model="values.serviceAreas"
          style="width: 360px"
          multiple
          allow-create
          filterable
          @change="handleServiceChange"
        >
          <el-option
            v-for="item in serviceAreaList.content"
            :key="item.name"
            :value="item.name"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        required
        :label="$t('service.selectionList[1]')"
        prop="foreignCountry"
        v-if="isChina"
      >
        <el-select
          v-model="values.foreignCountry"
          allow-create
          filterable
          style="width: 360px"
          @change="hadnleCountryChange"
        >
          <el-option
            v-for="item in outCountryList"
            :key="item.name"
            :value="item.name"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import api from "@/api/account";
import API from "@/api";
import { getToken } from "@/utils/auth";
import validation, { isDomestic } from "@/utils/validation";
import { ACCOUNT_TYPE } from "@/utils/constants";

import Avatar from "./avatar";

const actionUrl = "/api/frLegalPlatform/common/qiniu/upload";

export default {
  name: "Organization",
  components: {
    Avatar,
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ACCOUNT_TYPE,
      initValues: {},
      values: {
        name: "",
      },
      headers: {
        accessToken: getToken(),
      },
      fileList: [],
      actionUrl,
      outCountryList: [],
      provinceList: [],
      cityList: [],
      isChina: true,
      labelposition: localStorage.getItem("lang") === "zh" ? "right" : "top",
    };
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "areaTree",
      "dictionaries",
      "serviceAreaList",
      "dictionaryList",
    ]),
    cities() {
      if (this.values.province) {
        const province = this.areaTree.find((node) => {
          return node.id === this.values.province;
        });
        if (!province) return [];
        return province.children;
      }
      return [];
    },
    industry() {
      return this.dictionaries["INDUSTRY"]
        ? this.dictionaries["INDUSTRY"].list
        : [];
    },
    fieldOptions() {
      const dicts = this.dictionaries;
      const type = this.userInfo.userType;
      const dic =
        type === "LAW_OFFICE"
          ? dicts["BUSINESS_TYPE"]
          : dicts["TRANSLATION_BUSINESS_TYPE"];
      return dic ? dic.list : [];
    },
    rules() {
      return {
        name: [{ required: true, message: this.$t("520f308") }],
        fields: [{ required: true, message: this.$t("f548aaa") }],
        serviceAreas: [
          { required: true, message: this.$t("service.selectionList[0]") },
        ],
        foreignCountry: [
          { required: true, message: this.$t("service.selectionList[1]") },
        ],
        creditCode: [
          { required: true, message: this.$t("1941316") },
          { validator: validation.creditCode_zh },
        ],
        legalPerson: [
          { required: true, message: this.$t("94d558b") },
          { validator: validation.name },
        ],
        idCard: [
          { required: true, message: this.$t("8f5073c") },
          { validator: validation.idNumber(isDomestic()) },
        ],
        province: [{ required: true, message: "请选择机构所在地" }],
        address: [
          { required: true, message: this.$t("979d2a6") },
          { validator: validation.address },
        ],
        establishedTime: [{ required: true, message: this.$t("05141ca") }],
        industryId: [{ required: true, message: "请选择所属行业" }],
      };
    },
  },
  beforeUpdate() {
    this.labelposition =
      localStorage.getItem("lang") === "zh" ? "right" : "top";
  },
  mounted() {
    this.getAreaTree();
    this.getDictionaryList({
      type: "COUNTRY",
    });
    this.getServiceAreaList({
      type: "BUSINESS_TYPE",
    });
    if (this.userInfo.userType === "LAW_OFFICE") {
      this.getDictionary({ type: "BUSINESS_TYPE" });
    } else {
      this.getDictionary({ type: "TRANSLATION_BUSINESS_TYPE" });
    }

    console.log(this.dictionaries, this.serviceAreaList);
    api.getAccount(this.userInfo.userType).then((res) => {
      if (res.provinceId) {
        const element = this.provinceList.find((d) => d.id === res.provinceId);
        this.cityList = element.children;
      }
      const values = {
        picture: res.picture,
        name: res.name,
        creditCode: res.creditCode,
        idCard: res.idCard,
        legalPerson: res.legalPerson,
        province: res.provinceId,
        city: res.cityId,
        countryId: res.countryId,
        address: res.address,
        establishedTime: res.establishedTime,
        industryId: res.industryId,
        organizationType: res.organizationType,
        fields: res.field ? res.field.split(",") : [],
        serviceAreas: res.serviceArea ? res.serviceArea.split(",") : [],
        foreignCountry: res.foreignCountry,
        foreignCountryId: res.foreignCountryId,
      };
      this.values = { ...values };
      this.initValues = { ...values };
      this.isChina = res.countryId === 1;
      this.outCountryList = this.dictionaryList.content.filter(
        (d) => d.id !== 1
      );
    });
  },
  methods: {
    ...mapActions([
      "getAreaTree",
      "getDictionary",
      "getServiceAreaList",
      "getDictionaryList",
    ]),
    handleChange() {
      i;
      delete this.values.city;
    },
    reset() {
      this.values = { ...this.initValues };
    },
    hadnleCountryChange(name) {
      const country = this.dictionaryList.content.find((d) => d.name === name);
      this.values.foreignCountryId = country.id;
    },
    hadnleAreaChange(name) {
      const country = this.dictionaryList.content.find((d) => d.name === name);
      console.log(country);
      this.values.countryId = country.id;
    },
    handleFieldChange(value) {
      this.values.fields = value;
      this.values.field = value.join(",");
    },
    handleServiceChange(value) {
      this.values.serviceAreas = value;
      this.values.serviceArea = value.join(",");
    },
    changeProvince(val) {
      this.values.city = "";
      if (val) {
        for (let index = 0; index < this.provinceList.length; index++) {
          const element = this.provinceList[index];
          if (element.id === val) {
            this.cityList = element.children;
            break;
          }
        }
      }
    },
    getAreaTree() {
      API.common
        .getAreaTree()
        .then((res) => {
          if (res && res.content) {
            this.provinceList = res.content;
          }
        })
        .catch(() => {
          log("Interface exception API.common.getAreaTree()");
        });
    },
    autoPrefix(name) {
      let fun = "$t";
      if (this.abroad) {
        fun = "geEn";
      }
      return this[fun](name);
    },
  },
};
</script>

<style scoped lang="less">
.header {
  text-align: center;
  h2 {
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
  }
  p {
    margin: 20px 0 30px 0;
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
  }
}
.uploadenclosure {
  display: flex;
  flex-direction: row;
}
.provinceContainer {
  display: flex;
  & /deep/ .el-form-item__content {
    width: 180px;
  }
}
</style>
