<template>
  <div>
    <div class="header">
      <Avatar
        v-model="values.picture"
        :value="values.picture"
        :disabled="disabled"
      />

      <h2>{{ userInfo.username }}</h2>
    </div>
    <el-form
      ref="form"
      label-suffix="："
      label-width="150px"
      class="demo-form-inline"
      :model="values"
      :rules="rules"
      :disabled="disabled"
    >
      <!-- <el-form-item required label="律师名" prop="name">
      <el-input
        v-model="values.name"
        placeholder="请输入律师名"
        style="width: 360px"
      />
    </el-form-item>
    <el-form-item required label="律师身份证号" prop="creditCode">
      <el-input
        v-model="values.creditCode"
        placeholder="请输入律师身份证号"
        style="width: 360px"
      />
    </el-form-item>
    <el-form-item required label="律师执业证号" prop="creditCode">
      <el-input
        v-model="values.creditCode"
        placeholder="请输入律师执业证号"
        style="width: 360px"
      />
    </el-form-item>
    <el-form-item required label="所属律所" prop="province">
      <div style="width:360px">
        <el-select v-model="values.province" @change="handleChange">
          <el-option
            v-for="item in areaTree.list"
            :value="item.id"
            :key="item.id"
            :label="item.name"
          />
        </el-select>
      </div>
    </el-form-item>
     <el-form-item required label="擅长领域" prop="province">
      <div style="width:360px">
        <el-select v-model="values.province" @change="handleChange">
          <el-option
            v-for="item in areaTree.list"
            :value="item.id"
            :key="item.id"
            :label="item.name"
          />
        </el-select>
      </div>
    </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { getToken } from '@/utils/auth'
import Avatar from './avatar'

const actionUrl = '/api/frLegalPlatform/common/qiniu/upload'

export default {
  name: 'Personal',
  components: {
    Avatar,
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initValues: {},
      values: {},
      rules: {},
      headers: {
        accessToken: getToken(),
      },
      fileList: [],
      actionUrl,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    const values = {
      name: this.userInfo.name,
      picture: this.userInfo.picture,
    }
    this.values = { ...values }
    this.initValues = { ...values }
  },
  methods: {
    handleChange() {
      delete this.values.city
    },
    uploadSuccess(response, file, fileList) {
      // console.log(response, file, fileList);
      this.values.annex = response.data.url
      this.values.fileName = response.data.fileName
    },
    handleRemove() {
      this.values.annex = undefined
      this.values.fileName = undefined
    },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt10M = file.size / 1024 / 1024 < 10

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isLt10M
    },
    reset() {
      this.values = { ...this.initValues }
    },
  },
}
</script>

<style scoped lang="less">
.header {
  text-align: center;
  h2 {
    margin: 30px 0;
    font-size: 20px;
    font-weight: bold;
  }
}
.uploadenclosure {
  display: flex;
  flex-direction: row;
}
</style>
