<template>
  <div>
    <div class="header">
      <Avatar
        v-model="values.picture"
        :value="values.picture"
        :disabled="disabled"
      />

      <h2>{{ userInfo.username }}</h2>
    </div>
    <el-form
      ref="form"
      label-suffix="："
      label-width="150px"
      class="demo-form-inline"
      :model="values"
      :rules="rules"
      :disabled="disabled"
    >
      <el-form-item required :label="$t('668ee21')" prop="name">
        <el-input
          v-model="values.name"
          :placeholder="$t('077447e')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('f037b71')" prop="idCard">
        <el-input
          v-model="values.idCard"
          :placeholder="$t('ca639c2')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('d3a624e')" prop="licenseNumber">
        <el-input
          v-model="values.licenseNumber"
          :placeholder="$t('8eba518')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('a3c3439')" prop="organizationName">
        <el-select
          v-model="values.organizationName"
          allow-create
          filterable
          style="width: 360px"
          @change="handleOrgChange"
        >
          <el-option
            v-for="item in lawOffices"
            :key="item.name"
            :value="item.name"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <div v-if="isChina" class="provinceContainer">
        <el-form-item required :label="autoPrefix('b402761')" prop="province">
          <el-select
            v-model="values.province"
            :placeholder="autoPrefix('8c7aeca')"
            @change="changeProvince"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="city" label-width="0">
          <el-select v-model="values.city" :placeholder="autoPrefix('8c7aeca')">
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item
        required
        :label="$t('service.selectionList[1]')"
        prop="foreignCountry"
        v-if="isChina"
      >
        <el-select
          v-model="values.foreignCountry"
          allow-create
          filterable
          style="width: 360px"
          @change="hadnleCountryChange"
        >
          <el-option
            v-for="item in outCountryList"
            :key="item.name"
            :value="item.name"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!isChina"
        required
        :label="$t('b402761')"
        prop="countryId"
      >
        <el-select
          v-model="values.countryId"
          allow-create
          filterable
          style="width: 360px"
          @change="hadnleAreaChange"
        >
          <el-option
            v-for="item in outCountryList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item required :label="$t('003d5ff')" prop="fields">
        <el-select
          :value="field"
          style="width: 360px"
          multiple
          allow-create
          filterable
          @change="handleFieldChange"
        >
          <el-option
            v-for="item in fieldOptions"
            :key="item.name"
            :value="item.name"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item required :label="$t('a5134bb')" prop="description">
        <el-input
          v-model="values.description"
          type="textarea"
          :placeholder="$t('请输入律师简介')"
          style="width: 360px"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import api from "@/api/account";
import API from "@/api";
import validation, { isDomestic } from "@/utils/validation";
import { getToken } from "@/utils/auth";

import Avatar from "./avatar";

const actionUrl = "/api/frLegalPlatform/common/qiniu/upload";

export default {
  name: "Lawyer",
  components: {
    Avatar,
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initValues: {},
      values: {
        name: "",
      },
      headers: {
        accessToken: getToken(),
      },
      fileList: [],
      actionUrl,
      outCountryList: [],
      provinceList: [],
      cityList: [],
      isChina: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "dictionaries", "lawOffices", "dictionaryList"]),
    fieldOptions() {
      return this.dictionaries["BUSINESS_TYPE"]
        ? this.dictionaries["BUSINESS_TYPE"].list
        : [];
    },
    field() {
      const names = this.values.field ? this.values.field.split(",") : [];
      return [...names];
    },
    rules() {
      return {
        name: [{ required: true, message: this.$t("077447e") }],
        fields: [{ required: true, message: this.$t("54b3565") }],
        idCard: [
          { required: true, message: this.$t("ca639c2") },
          { validator: validation.idNumber(isDomestic()) },
        ],
        licenseNumber: [{ required: true, message: this.$t("8eba518") }],
        organizationName: [{ required: true, message: this.$t("fa5ab00") }],
        description: [
          { required: true, message: this.$t("请输入律师简介") },
          { validator: validation.description },
        ],
      };
    },
  },
  methods: {
    ...mapActions(["getDictionary", "getLawOffices", "getDictionaryList"]),
    validateField(rule, value, callback) {
      if (!(value || this.values.fieldId)) {
        callback(new Error(this.$t("54b3565")));
      }
      callback();
    },
    handleFieldChange(value) {
      this.values.fields = value;
      this.values.field = value.join(",");
    },
    handleOrgChange(name) {
      const org = this.lawOffices.filter((i) => i.name === name);
      if (org[0]) {
        this.values.organizationId = org[0].id;
      } else {
        this.values.organizationId = 0;
      }
    },
    hadnleCountryChange(name) {
      const country = this.dictionaryList.content.find((d) => d.name === name);
      this.values.foreignCountryId = country.id;
    },
    hadnleAreaChange(name) {
      const country = this.dictionaryList.content.find((d) => d.name === name);
      console.log(country);
      this.values.countryId = country.id;
    },
    changeProvince(val) {
      this.values.city = "";
      if (val) {
        for (let index = 0; index < this.provinceList.length; index++) {
          const element = this.provinceList[index];
          if (element.id === val) {
            this.cityList = element.children;
            break;
          }
        }
      }
    },
    getAreaTree() {
      API.common
        .getAreaTree()
        .then((res) => {
          if (res && res.content) {
            this.provinceList = res.content;
          }
        })
        .catch(() => {
          log("Interface exception API.common.getAreaTree()");
        });
    },
    autoPrefix(name) {
      let fun = "$t";
      if (this.abroad) {
        fun = "geEn";
      }
      return this[fun](name);
    },
    // grouping(list) {
    //   let ids = []
    //   let names = []
    //   for(let i of list) {
    //     if(typeof i === 'number') {
    //       ids.push(i)
    //     }
    //     else {
    //       names.push(i)
    //     }
    //   }
    //   return {ids, names}
    // }
    reset() {
      this.values = { ...this.initValues };
    },
  },
  created() {
    this.getAreaTree();
    this.getDictionaryList({ type: "COUNTRY" });
  },
  mounted() {
    // this.getAreaTree();
    this.getDictionary({ type: "BUSINESS_TYPE" });
    this.getLawOffices();
    console.log("dictionaryList", this.dictionaryList);
    if (this.userInfo.userType) {
      api.getAccount(this.userInfo.userType).then((res) => {
        if (res.province) {
          const element = this.provinceList.find((d) => d.id === res.province);
          this.cityList = element.children;
        }
        const names = res.field ? res.field.split(",") : [];
        const values = {
          name: res.name,
          field: res.field,
          fieldId: res.fieldId,
          countryId: res.countryId,
          picture: res.picture,
          idCard: res.idCard,
          licenseNumber: res.licenseNumber,
          organizationName: res.organizationName,
          organizationId: res.organizationId,
          description: res.description,
          foreignCountry: res.foreignCountry,
          foreignCountryId: res.foreignCountryId,
          province: res.province,
          city: res.city,
          fields: [...names],
        };
        this.initValues = { ...values };
        this.values = { ...values };
        this.isChina = res.countryId === 1;
        this.outCountryList = this.dictionaryList.content.filter(
          (d) => d.id !== 1
        );
      });
    }
  },
};
</script>

<style scoped lang="less">
.header {
  text-align: center;
  h2 {
    margin: 30px 0;
    font-size: 20px;
    font-weight: bold;
  }
}
.uploadenclosure {
  display: flex;
  flex-direction: row;
}
.provinceContainer {
  display: flex;
  & /deep/ .el-form-item__content {
    width: 180px;
  }
}
</style>
