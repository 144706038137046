<template>
  <div>
    <div class="header">
      <Avatar
        v-model="values.picture"
        :value="values.picture"
        :disabled="disabled"
      />

      <h2>{{ userInfo.username }}</h2>
    </div>
    <el-form
      ref="form"
      label-suffix="："
      label-width="150px"
      class="demo-form-inline"
      :model="values"
      :rules="rules"
      :disabled="disabled"
    >
      <el-form-item required :label="$t('7e42717')" prop="name">
        <el-input
          v-model="values.name"
          :placeholder="$t('2f946ff')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('af975db')" prop="creditCode">
        <el-input
          v-model="values.creditCode"
          :placeholder="$t('1941316')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item :label="$t('1d0a243')" required prop="businessLicense">
        <el-upload
          class="uploadenclosure"
          :headers="headers"
          :action="actionUrl"
          :multiple="false"
          :limit="1"
          :file-list="fileList"
          :on-success="uploadSuccess"
          :on-remove="handleRemove"
          :before-upload="beforeUpload"
          :on-preview="handlePreview"
        >
          <el-button class="addButton" size="small">
            <i class="icon el-icon-upload2" style="margin-right: 8px"></i>{{ $t('1c88292') }}
          </el-button>
        </el-upload>
      </el-form-item>
      <el-form-item required :label="$t('62f7e8a')" prop="province">
        <div style="width: 360px">
          <el-select
            v-if="userInfo.country === 'CHINA'"
            v-model="values.province"
            style="width: 180px"
            @change="provinceChange"
          >
            <el-option
              v-for="item in areaTree.list"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
          <el-select v-model="values.city" style="width: 180px">
            <el-option
              v-for="item in cities"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item required :label="$t('95c9900')" prop="address">
        <el-input
          v-model="values.address"
          :placeholder="$t('2640133')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('1f832ea')" prop="establishedTime">
        <el-date-picker
          v-model="values.establishedTime"
          type="date"
          :placeholder="$t('215b874')"
          style="width: 360px"
        />
      </el-form-item>
      <el-form-item required :label="$t('5c1f642')" prop="industryId">
        <el-select v-model="values.industryId" style="width: 360px">
          <el-option
            v-for="item in industry"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <image-viewer
      v-if="showPreview"
      :on-close="closePreview"
      :url-list="urlList"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ImageViewer from 'element-ui/packages/image/src/image-viewer'

import api from '@/api/account'
import { getToken } from '@/utils/auth'
import validation from '@/utils/validation'

import Avatar from './avatar'

const actionUrl = '/api/frLegalPlatform/common/qiniu/upload'

export default {
  name: 'Enterprise',
  components: {
    Avatar,
    ImageViewer,
  },
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initValues: {},
      showPreview: false,
      urlList: [],
      values: {
        name: '',
      },
      headers: {
        accessToken: getToken(),
      },
      fileList: [],
      actionUrl,
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'areaTree', 'dictionaries']),
    cities() {
      if (this.values.province) {
        const province = this.areaTree.find((node) => {
          return node.id === this.values.province
        })
        if (!province) return []
        return province.children
      }
      return []
    },
    industry() {
      return this.dictionaries['INDUSTRY']
        ? this.dictionaries['INDUSTRY'].list
        : []
    },
    rules() {
      return {
        name: [{ required: true, message: this.$t('2f946ff') }],
        creditCode: [
          { required: true, message: this.$t('1941316') },
          { validator: validation.creditCode_zh },
        ],
        businessLicense: [{ required: true, message: this.$t('ab9ed8d') }],
        province: [{ required: true, message: '请选择企业所在地' }],
        address: [
          { required: true, message: this.$t('2640133') },
          { validator: validation.address },
        ],
        establishedTime: [{ required: true, message: this.$t('215b874') }],
        industryId: [{ required: true, message: this.$t('aaa01e1') }],
      }
    }
  },
  methods: {
    ...mapActions(['getAreaTree', 'getDictionary']),
    provinceChange(id) {
      delete this.values.city
    },

    uploadSuccess(response, file, fileList) {
      // console.log(response, file, fileList);
      this.values.businessLicense = response.data.url
    },
    handleRemove() {
      this.values.businessLicense = undefined
    },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt10M = file.size / 1024 / 1024 < 10

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
      return isLt10M
    },
    handlePreview(file) {
      console.log(file)
      this.showPreview = true
      this.urlList = [file.url]
    },
    closePreview() {
      this.showPreview = false
    },
    reset() {
      this.values = { ...this.initValues }
    },
  },
  mounted() {
    this.getAreaTree()
    this.getDictionary({ type: 'INDUSTRY' })
    api.getAccount(this.userInfo.userType).then((res) => {
      const values = {
        picture: res.picture,
        name: res.name,
        creditCode: res.creditCode,
        businessLicense: res.businessLicense,
        province: res.provinceId,
        city: res.cityId,
        address: res.address,
        establishedTime: res.establishedTime,
        industryId: res.industryId,
      }
      this.values = { ...values }
      this.initValues = { ...values }

      if (res.businessLicense) {
        this.fileList = [{ name: '营业执照', url: res.businessLicense }]
      }
    })
  },
}
</script>

<style scoped lang="less">
.header {
  text-align: center;
  h2 {
    margin: 30px 0;
    font-size: 20px;
    font-weight: bold;
  }
}
.uploadenclosure {
  display: flex;
  flex-direction: row;
}
</style>
